(function() {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PropertyVoucherCashDetailDialogController', PropertyVoucherCashDetailDialogController);

    function PropertyVoucherCashDetailDialogController(
        $scope,
        $rootScope,
        $uiRouterGlobals,
        $state,
        $filter,
        $uibModalInstance,
        currentProperty,
        currentPropertyGroup,
        voucherConfig,
        allVouchers,
        ShoppingCartAdd,
        $q,
        DetermineItemLanguage,
        $translate,
        Analytics,
        $timeout,
        shoppingCart,
        $sce,
        ModalService,
        voucherPool,
        $window,
        ENV,
        PromotionCodeService,
        CurrencyExchange,
        currentLanguage
    ) {
        'ngInject';
        var vm = this;



        vm.$onInit = function() {
            console.log('=> $onInit \t PropertyVoucherCashDetailDialogController')

            /**
             * resolved values from parent state
             */
            vm.addToCartProgress = false;
            vm.shoppingCart = shoppingCart;
            vm.voucherConfig = voucherConfig;
            vm.currentProperty = currentProperty;
            vm.currentPropertyGroup = currentPropertyGroup;
            var isGroup = typeof vm.currentPropertyGroup !== 'undefined';
            vm.allVouchers = allVouchers; //unused

            /**
             * resolved values from this state
             */
            vm.voucherPool = voucherPool;

            /**
             * params from URL
             */
            vm.focusedAmount = $uiRouterGlobals.params.focusedAmount;

            /**
             * variables used in modal
             */
            vm.isBulk = !!($uiRouterGlobals.params.bulk || $uiRouterGlobals.params.contextType == 'refunds' || voucherConfig[currentProperty.hotelId].configMap.isBulk === 'true');
            vm.dropdownVouchers = [];
            angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                vm.dropdownVouchers.push(voucher);
            });
            vm.dropdownVouchers.sort(function(v1, v2) {
                return v1.totalRate.priceMin - v2.totalRate.priceMin;
            });

            // because we can have dropdownVouchers populated with vouchers from pool
            // if we need to still select typed amount we need to keep separate 'typedAmountVoucher' entity
            vm.typedAmountVoucher;
            enableTypedAmounts(); //this will set vm.typedAmountVoucher
            vm.selectedTypedAmount;

            //populate dropdown default selection
            vm.selectedDefaultVoucher = getDefaultVoucher();

            //
            vm.voucherProperty =
                typeof vm.currentPropertyGroup === 'undefined'
                    ? getVoucherPropertyForIndividualSite()
                    : getVoucherPropertyForGroupSite();

            vm.addItemToCartAndRefresh = addItemToCartAndRefresh;
            vm.addItemToCartAndCheckout = addItemToCartAndCheckout;
            vm.buyInBulk = buyInBulk;
            vm.clear = clear;
            vm.hideClear = hideClear();
            vm.valueForDropdown = valueForDropdown;
            vm.valueForDropdownChanged = valueForDropdownChanged;
            vm.valueForInputChanged = valueForInputChanged;
            vm.giftCard = voucherPool.giftCard;
            vm.getGiftCardImageFileName = getGiftCardImageFileName;
            vm.getImageFileName = getImageFileName;
            vm.getSelectedVoucherDescription = getSelectedVoucherDescription;
            vm.getSelectedVoucherDescription2 = getSelectedVoucherDescription2;

            vm.getSelectedVoucherFirstApplicableCategoryImage = getSelectedVoucherFirstApplicableCategoryImage;

            vm.getVoucherInContext = getVoucherInContext;

            //just in case we go into full view of cash vouchers and skip list view
            $rootScope.$broadcast('propertySelected', { property: vm.currentProperty });

            //$timeout(function(){
            trackProductDetail();
            //}, 1000);

            vm.displayActionButtons = {
                display: true,
                errorCode: undefined,
                errorValue: undefined,
            };
            vm.maxTransactionLimit = parseInt(voucherConfig[currentProperty.hotelId].configMap.maxTransactionLimit) || 0;
            vm.limitQuantity = parseInt(voucherConfig[currentProperty.hotelId].configMap.limitQuantity) || 0;

            if (vm.maxTransactionLimit > 0 || vm.limitQuantity > 0) {
                displayActionButtons(
                    vm.maxTransactionLimit,
                    vm.limitQuantity,
                    vm.selectedDefaultVoucher.totalRateAfterDiscount.priceMin,
                    vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId],
                    vm.shoppingCart.shoppingCartItems.items.length
                );
            }

            vm.appliedPromotionCode = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, isGroup, vm.selectedDefaultVoucher);

            //currency
            vm.baseCurrency = {
                code : vm.selectedDefaultVoucher.currency
            };

            vm.currentCurrency = {
                code : vm.baseCurrency.code
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if(currentCurrency && currentCurrency!=null){
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });



            //need this to close modal on back button press.
            $scope.$on('$stateChangeStart', function(e, to, toParams, from, fromParams) {
                var currentModal = ModalService.getCurrentModal();
                console.log('$stateChangeStart', currentModal);
                var sameState = false;
                //if we stay in the same state do not go to parent
                if(to && from && to.name==from.name){
                    sameState = true;
                }

                if (angular.isDefined(currentModal)) {
                    if (sameState){
                        currentModal.dismiss('doNotGoToParent');
                    }
                    else if(toParams.voucherId!=fromParams.voucherId){
                        currentModal.dismiss('voucherIdChanged');//same result as doNotGoToParent
                    } else {
                        currentModal.dismiss('cancel');
                    }

                }
            });

            sendDataLayerProductImpression();

        }

        function enableTypedAmounts() {
            if (voucherPool.enableTypedAmounts) {
                //copy the details over to 'typedAmountVoucher'
                if (!vm.typedAmountVoucher) {
                    vm.typedAmountVoucher = angular.copy(vm.dropdownVouchers[0]);
                }
            }
        }

        function getVoucherInContext() {
            var v =
                vm.typedAmountVoucher && vm.selectedTypedAmount && vm.selectedTypedAmount > 0
                    ? vm.typedAmountVoucher
                    : vm.selectedDefaultVoucher;
            if (!vm.selectedDefaultVoucher) {
                v = vm.typedAmountVoucher; //???
            }
            return v;
        }

        function getDefaultVoucher() {
            var defaultSelection = vm.dropdownVouchers[0];
            if (
                !vm.focusedAmount &&
                (!voucherConfig ||
                    !voucherConfig[currentProperty.hotelId] ||
                    !voucherConfig[currentProperty.hotelId].configMap ||
                    !voucherConfig[currentProperty.hotelId].configMap.cashDefault)
            ) {
                return defaultSelection;
            }

            var defaultAmount = vm.focusedAmount || voucherConfig[currentProperty.hotelId].configMap.cashDefault;

            var closestRank = Math.abs(defaultSelection.totalRate.priceMin - defaultAmount);

            angular.forEach(vm.dropdownVouchers, function(value, key) {
                var valueRank = Math.abs(value.totalRate.priceMin - defaultAmount);
                if (valueRank <= closestRank) {
                    defaultSelection = value;
                    closestRank = valueRank;
                }
            });

            return defaultSelection;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function hideClear() {
            return $uiRouterGlobals.current.data ? $uiRouterGlobals.current.data.hideClear : false;
        }

        function valueForDropdown(voucher) {
            var valueFormatted = $filter('exchangenumber')(voucher.totalRate.priceMin, voucher.currency, voucher.currency, true);
            var fullValueFormatted = voucher.currency + ' ' + valueFormatted;
            if(voucher.currency==vm.currentCurrency.code){
                return fullValueFormatted;
            }
            var exchangedValueFormatted = $filter('exchangenumber')(voucher.totalRate.priceMin, voucher.currency, vm.currentCurrency.code, true);
            var fullExchangedValueFormatted = vm.currentCurrency.code + ' ' + exchangedValueFormatted;
            return fullExchangedValueFormatted;// + ' ('+ fullValueFormatted+')';
        }

        function valueForDropdownChanged() {
            if (vm.maxTransactionLimit > 0 || vm.limitQuantity > 0) {
                displayActionButtons(
                    vm.maxTransactionLimit,
                    vm.limitQuantity,
                    vm.selectedDefaultVoucher.totalRate.priceMin,
                    vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId],
                    vm.shoppingCart.shoppingCartItems.items.length
                );
            }
            vm.selectedTypedAmount = '';
            trackProductDetail();
        }

        function valueForInputChanged() {
            console.log('valueForInputChanged', vm.typedAmountVoucher,vm.selectedTypedAmount);
            //set prices on voucher
            var typedAmountInCurrentCurrency = vm.selectedTypedAmount;
            var typedAmountInVoucherCurrency = $filter('exchangenumber')(typedAmountInCurrentCurrency,vm.currentCurrency.code,vm.typedAmountVoucher.currency);
            console.log('typedAmountInVoucherCurrency', typedAmountInVoucherCurrency);
            vm.typedAmountVoucher.totalRate.priceMin = typedAmountInVoucherCurrency;
            vm.typedAmountVoucher.totalRateChannel.priceMin = typedAmountInVoucherCurrency;
            var beforeDiscountAmount = typedAmountInVoucherCurrency;
            if (!vm.appliedPromotionCode) {
                vm.typedAmountVoucher.totalRateAfterDiscount.priceMin = beforeDiscountAmount;
                vm.typedAmountVoucher.totalRateChannelAfterDiscount.priceMin = beforeDiscountAmount;
            } else {
                if (vm.appliedPromotionCode.amountPercentage) {
                    var afterDiscountAmount =
                        beforeDiscountAmount - beforeDiscountAmount * (vm.appliedPromotionCode.discountAmount / 100.0);
                    vm.typedAmountVoucher.totalRateAfterDiscount.priceMin = afterDiscountAmount;
                    vm.typedAmountVoucher.totalRateChannelAfterDiscount.priceMin = afterDiscountAmount;
                } else {
                    var afterDiscountAmount = beforeDiscountAmount - vm.appliedPromotionCode.discountAmount;
                    vm.typedAmountVoucher.totalRateAfterDiscount.priceMin = afterDiscountAmount;
                    vm.typedAmountVoucher.totalRateChannelAfterDiscount.priceMin = afterDiscountAmount;
                }
            }

            console.log('test display', vm.maxTransactionLimit, vm.typedAmountVoucher.totalRateAfterDiscount.priceMin);
            if (vm.maxTransactionLimit > 0 || vm.limitQuantity > 0) {
                displayActionButtons(
                    vm.maxTransactionLimit,
                    vm.limitQuantity,
                    vm.typedAmountVoucher.totalRateAfterDiscount.priceMin,
                    vm.shoppingCart.shoppingCartItems.totalPerProperty[currentProperty.hotelId],
                    vm.shoppingCart.shoppingCartItems.items.length
                );
            }
        }

        function displayActionButtons(maxTransactionLimit, maxItemLimit, selectedAmount, total, totalItems) {
            //console.log('displayActionButtons', maxTransactionLimit, selectedAmount, total);
            //1.
            var maxTransactionLimitPass = false;
            if (angular.isUndefined(selectedAmount) || angular.isUndefined(total) || maxTransactionLimit == 0) {
                maxTransactionLimitPass = true;
            } else if (total + selectedAmount <= maxTransactionLimit) {
                maxTransactionLimitPass = true;
            }
            //2.
            var maxItemsLimitPass = false;
            if (angular.isUndefined(totalItems) || maxItemLimit == 0) {
                maxItemsLimitPass = true;
            } else if (totalItems + 1 <= maxItemLimit) {
                maxItemsLimitPass = true;
            }

            if (!maxTransactionLimitPass) {
                vm.displayActionButtons.display = false;
                vm.displayActionButtons.errorCode = 'vouchers.maxtransactionlimit';
                vm.displayActionButtons.errorValue = maxTransactionLimit;
            } else if (!maxItemsLimitPass) {
                vm.displayActionButtons.display = false;
                vm.displayActionButtons.errorCode = 'vouchers.maxitemslimit';
                vm.displayActionButtons.errorValue = maxItemLimit;
            } else {
                vm.displayActionButtons.display = true;
                vm.displayActionButtons.errorCode = undefined;
                vm.displayActionButtons.errorValue = undefined;
            }

            return maxTransactionLimitPass && maxItemsLimitPass;
        }

        function getSelectedVoucherDescription(fallbackDescription) {
            var description;
            if (vm.giftCard) {
                description = $translate.instant('giftcards.cash.description');
            } else if (voucherPool.cashVoucherDescription && voucherPool.cashVoucherDescription != '') {
                description = voucherPool.cashVoucherDescription;
            } else {
                description = getVoucherInContext().voucherDescription;
            }
            if (description == null || description === undefined || description == '') {
                return $sce.trustAsHtml('<span></span>');
            }
            return $sce.trustAsHtml(description);
        }

        function getSelectedVoucherDescription2(fallbackDescription) {
            var description;
            if (!vm.giftCard) {
                description = getVoucherInContext().voucherDescription2;
            }
            if (description == null || description === undefined || description == '') {
                return $sce.trustAsHtml('<span></span>');
            }
            return $sce.trustAsHtml(description);
        }

        function getGiftCardImageFileName() {
            if (vm.giftCard && vm.giftCard.imageUrl && vm.giftCard.imageUrl.trim().length > 0) {
                return useImgix(vm.giftCard.imageUrl);
            }
            return getImageFileName();
        }

        function getImageFileName(){
            var giftCard = vm.giftCard;
            if(giftCard && giftCard.imageUrl && giftCard.imageUrl!=null && giftCard.imageUrl.trim().length>0){
                return useImgix( giftCard.imageUrl);
            }
            var firstVoucher = undefined;
            angular.forEach(voucherPool.vouchers, function(voucher, vkey) {
                firstVoucher = firstVoucher || voucher;
            });
            if (firstVoucher && firstVoucher.images && firstVoucher != null && firstVoucher.images[7]) {
                return useImgix(firstVoucher.images[7]);
            } else {
                return "//in1-image-library.imgix.net/no-image.jpg";
            }
        }

        function getSelectedVoucherFirstApplicableCategoryImage() {
            var firstVoucher = vm.dropdownVouchers[0];
            var firstCategory = undefined;
            angular.forEach(firstVoucher.categories, function(c, ckey) {
                firstCategory = firstCategory || c;
            });
            if (firstCategory && firstCategory.imageUrl) {
                return useImgix(firstCategory.imageUrl);
            }
            return null;
        }

        function useImgix(imgUrl) {
            var old_host = 'dddo4e1h8fdy4.cloudfront.net';
            var new_host = 'in1-image-library.imgix.net';
            return imgUrl.replace(old_host, new_host);
        }

        function getVoucherPropertyForIndividualSite() {
            var voucher = getVoucherInContext();
            var property = vm.currentProperty;
            if (voucher.hotelId != property.hotelId) {
                //try among master hotels
                if (vm.currentProperty.masterProperties && vm.currentProperty.masterProperties != null) {
                    angular.forEach(vm.currentProperty.masterProperties, function(hotel, hkey) {
                        if (hotel.hotelId == voucher.hotelId) {
                            property = hotel;
                        }
                    });
                }
            }

            return property;
        }

        function getVoucherPropertyForGroupSite() {
            if (!vm.dropdownVouchers || vm.dropdownVouchers.length == 0) {
                return undefined;
            }
            if (!vm.currentPropertyGroup || !vm.currentPropertyGroup.hotels) {
                return undefined;
            }
            var property = undefined;
            angular.forEach(vm.currentPropertyGroup.hotels, function(hotel, hkey) {
                if (hotel.hotelId == vm.dropdownVouchers[0].hotelId) {
                    property = hotel;
                }
            });
            return property;
        }

        function backToList(shoppingCart) {
            //we have to reload site to fetch new items in the nav bar
            //$state.transitionTo($state.current, $uiRouterGlobals.params, {
            //	  reload: false, inherit: true, notify: false
            //}).then(function() {
            $rootScope.$broadcast('itemAddedToCart', { shoppingCart: shoppingCart, openShoppingCartDropdown: true });
            //});
        }

        function goToShoppigCart() {
            //we have to reload site to fetch new items in the nav bar
            $state.transitionTo('shopping-cart', $uiRouterGlobals.params, { inherit: true, notify: true, reload: true });
        }

        function addItemToCartAndCheckout(event) {
            vm.addToCartProgress = true;
            addItemToCart(event, goToShoppigCart);
        }

        function addItemToCartAndRefresh(event) {
            vm.addToCartProgress = true;
            addItemToCart(event, backToList);
        }

        function addItemToCart(event, callback) {
            event.preventDefault();
            var voucher = getVoucherInContext();
            var itemVM = {
                propertyId: vm.voucherProperty.hotelId,
                propertyCode: vm.voucherProperty.hotelCode,
                propertyName: vm.voucherProperty.hotelName,
                propertyCountry: vm.voucherProperty.hotelCountry,
                itemId: voucher.id,
                itemName:
                    voucher.voucherType == 'cash-wildcard'
                        ? voucher.voucherName + ' ' + valueForDropdown(voucher)
                        : voucher.voucherName, //vm.currentProperty.hotelCurrency + ' ' + vm.selectedDefaultVoucher.totalRate,

                itemDescription: getSelectedVoucherDescription().$$unwrapTrustedValue(),
                collectionDescription: voucher.collectDescription,
                itemTotalPrice: voucher.totalRate.priceMin,
                itemCurrency: voucher.currency,
                itemType: voucher.voucherType,
                itemGiftCardId: vm.giftCard ? vm.giftCard.id : undefined,
                itemEmailCategoryId: !voucher.meta ? null : voucher.meta.selectedEmailCategoryId,
                itemFixedDeliveryType: !voucher.meta ? null : voucher.meta.fixedDeliveryOption,
                itemMeta: !voucher.meta ? null : voucher.meta,
                passkey: $uiRouterGlobals.params.passkey || '',
                channelId: (typeof currentPropertyGroup !== 'undefined' ? currentPropertyGroup.masterProperty.hotelId : $uiRouterGlobals.params.channel) || 0,
                selectedCurrency: CurrencyExchange.currencies.current ? CurrencyExchange.currencies.current.code : voucher.currency,
                exchange : CurrencyExchange.currencies.current ? CurrencyExchange.currencies.current.rate : 1
                //itemImageFileName : getImageFileName(vm.currentVoucher)//TODO
            };

            //build additional fields
            var additionalFields = {};
            if(voucher.meta && voucher.meta.redemptionEndDate && voucher.meta.redemptionEndDate!=null ){
                additionalFields['not_valid_after_date'] = new Date(voucher.meta.redemptionEndDate).getTime()/1000;
            }

            itemVM.additionalFields = additionalFields;

            //if we have vm.giftCardId, then we also have the image
            if (vm.giftCard && vm.giftCard.id) {
                itemVM.itemImageFileName = getGiftCardImageFileName();
            } else {
                itemVM.itemImageFileName = getImageFileName();
            }

            itemVM.itemLanguage = DetermineItemLanguage.getLanguage(itemVM);

            vm.clear();
            var cb = callback || angular.noop;
            addItem(itemVM, cb);
        }

        function addItem(itemVM, callback) {
            var cb = callback || angular.noop;

            //params needed to create shopping cart from scratch
            //build shopping cart locale
            var variant = '';
            if ($uiRouterGlobals.params.contextType) {
                variant = '_' + $uiRouterGlobals.params.contextType;
            }
            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

            var src = undefined;
            if (typeof currentPropertyGroup !== 'undefined') {
                src = currentPropertyGroup.code;
            }
            if ($uiRouterGlobals.params.src) {
                src = $uiRouterGlobals.params.src;
            }
            itemVM.requestParams = {};
            itemVM.requestParams.ref = $uiRouterGlobals.params.ref;
            itemVM.requestParams.src = src;
            itemVM.requestParams.lang = currentLanguage + variant;
            itemVM.requestParams.contextType = $uiRouterGlobals.params.contextType;
            itemVM.requestParams.propertyType = $uiRouterGlobals.params.propertyType;
            itemVM.requestParams.propertyCode = $uiRouterGlobals.params.propertyId;
            itemVM.requestParams.customerRef = $uiRouterGlobals.params.customerRef;

            return ShoppingCartAdd.add(
                itemVM,
                function(shoppingCart) {
                    trackAddToCart(itemVM);
                    return cb(shoppingCart);
                },
                function(err) {
                    //return cb(err);
                    $rootScope.$broadcast('itemAddedToCartError', {});
                }
            ).$promise;
        }

        function buyInBulk(event) {
            vm.clear();
            var voucher = getVoucherInContext();
            $timeout(function () {
                var params = {};//angular.extend({},$uiRouterGlobals.params);
                params.voucherId = voucher.id;

                params.voucherPool = vm.voucherPool;
                params.focusedAmount = voucher.totalRate.priceMin;
                params.appliedPromotionCode = vm.appliedPromotionCode;
                $state.transitionTo('bulk-add', params, { inherit: true, notify: true, reload: false });
            },10);
        }

        function trackProductDetail() {
            var voucher = getVoucherInContext();

            Analytics.addProduct(
                voucher.id, //productId
                voucher.voucherName, //name
                'cash', //category
                vm.voucherProperty.hotelCode, //brand
                '', //variant
                voucher.totalRate.priceMin, //price
                '1', //quantity
                '', //coupon
                '' //position
            );
            Analytics.trackDetail();
        }

        function trackAddToCart(itemVm) {
            var voucher = getVoucherInContext();

            Analytics.addProduct(
                voucher.id, //productId
                voucher.voucherName, //name
                'cash', //category
                vm.voucherProperty.hotelCode, //brand
                '', //variant
                voucher.totalRate.priceMin, //price
                '1', //quantity
                '', //coupon
                '' //position
            );
            Analytics.trackCart('add');

            //tag manager
            console.log('sendDataLayerAddToCart', itemVm);
            var dataLayer = ($window.dataLayer = $window.dataLayer || []);
            dataLayer.push({
                event: 'eec:addToCart',
                ecommerce: {
                    currencyCode: itemVm.itemCurrency,
                    add: {
                        products: [
                            {
                                name: itemVm.itemName,
                                id: itemVm.itemId.toString(),
                                price: itemVm.itemTotalPrice,
                                quantity: 1,
                                brand: itemVm.propertyName,
                                category: itemVm.itemType,
                            },
                        ],
                    },
                },
            });
        }

        var sendDataLayerProductImpression = function() {
            var voucher = getVoucherInContext();
            console.log('sendDataLayerProductImpression', voucher);

            var dataLayer = ($window.dataLayer = $window.dataLayer || []);
            dataLayer.push({
                event: 'eec:detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name:
                                    voucher.voucherType == 'cash-wildcard'
                                        ? voucher.currency + ' ' + $filter('number')(voucher.totalRate.priceMin, 2)
                                        : voucher.voucherName,
                                id: voucher.id,
                                price: voucher.totalRate.priceMin,
                                category: 'cash',
                            },
                        ],
                    },
                },
            });

            //ga4
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: voucher.currency,
                    value: voucher.totalRate.priceMin,
                    items: [
                        {
                            item_id: voucher.id,
                            item_name: voucher.voucherType == 'cash-wildcard'
                                ? voucher.currency + ' ' + $filter('number')(voucher.totalRate.priceMin, 2)
                                : voucher.voucherName,
                            index: 0,
                            price: voucher.totalRate.priceMin,
                            item_category: 'Gift Voucher'
                        }
                    ]
                }
            });

        };
    }
})();
