(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('PaymentTransactionsDialogController', PaymentTransactionsDialogController);

    function PaymentTransactionsDialogController($scope, $rootScope, $state, $stateParams, $anchorScroll, shoppingCart, PaymentFinishProcess, DetermineShopMoreState,Analytics, TagManager, GoogleAdwordsService, ENV, currentProperty, currentPropertyGroup, currentLanguage, propertyConfig, availablePaymentOptions) {
        'ngInject';
	    var vm = this;

	    $anchorScroll();

	    vm.transactionsCompleted = false;

	    vm.shoppingCart = shoppingCart;
	    vm.propertyConfig = propertyConfig;
	    vm.browseMore = browseMore;
	    vm.isApplicable = isApplicable;
        vm.availablePaymentOptions = availablePaymentOptions;

	    vm.transactions = shoppingCart.shoppingCartPaymentTransactions;


	    $scope.$watch('vm.transactions', finishTransactions, true);

	    function finishTransactions () {
	        console.log('finishTransactions...', vm.transactions.length)
            if (0 == vm.transactions.length) {
                //error no transactions
                console.error("no transactions", vm.transactions.length)
                return;
            }
	    	var completedCount = 0;
            var applicableCount = 0;
	    	//console.info("transactions: ",vm.transactions);
            angular.forEach(vm.transactions, function (t, key) {
                //transaction has items in the shopping bag
                if (isApplicable(t)) {
                    applicableCount++;
                    if (t.paymentTransactionStatusType == 'COMPLETED' && t.persistenceTransactionStatusType == 'SAVED') {
                        completedCount++;
                    }
                }
            });
            if (completedCount == applicableCount) {
                finishProcessAndGoToConfirmationState();
            }
            console.debug("completedCount == applicableCount", completedCount, applicableCount);
	    };

        function isApplicable(t){
            var applicable = false;
            angular.forEach(vm.shoppingCart.shoppingCartItems.items, function (item, index) {
                if (t.shoppingCartItemUUIDs.indexOf(item.uuid) > -1) {
                    applicable = true;
                }
            });
            return applicable;
        }


	    function finishProcessAndGoToConfirmationState() {
	        console.log('finishProcessAndGoToConfirmationState')
            finishProcess(goToConfirmationState);
	    };

        function goToConfirmationState(object) {
            console.log('=> goToConfirmationState', object)

            try {
                angular.forEach(vm.transactions, function (transaction) {
                    trackTransaction(transaction, object.confirmationUuid);
                });
            } catch (e) {
                console.error(e);
            }

            if (object.confirmationUuid) {
                $state.transitionTo('confirmation', {uuid: object.confirmationUuid}, {
                    reload: 'site', inherit: true, notify: true
                });
            }
        };

        function finishProcess (callback) {
            console.log('finishProcess...')
            var cb = callback || angular.noop;

            //build shopping cart locale
            var variant = '';
            if ($stateParams.contextType) {
                variant = '_' + $stateParams.contextType;
            }
            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

            var src = undefined;
            if (typeof currentPropertyGroup !== 'undefined') {
                src = currentPropertyGroup.code;
            }
            if ($stateParams.src) {
                src = $stateParams.src;
            }

            var params = {
                ref: $stateParams.ref,
                src: src,
                lang: currentLanguage + variant,
                contextType: $stateParams.contextType,
                propertyType: $stateParams.propertyType,
                propertyCode: $stateParams.propertyId,
                customerRef: $stateParams.customerRef,
                reset : $stateParams.reset

            };

            return PaymentFinishProcess.finish(params,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        };




	    function browseMore(event){
	    	event.preventDefault();
	    	var shopMoreState = DetermineShopMoreState.getShopMoreState();
	    	$state.go(shopMoreState.name, shopMoreState.params);
	    };

        function trackTransaction(transaction, confirmationUuid) {
            console.log('trackTransactions...')
            console.log('transaction', transaction)
            angular.forEach(vm.shoppingCart.shoppingCartItems.items, function (item, index) {
                if (transaction.shoppingCartItemUUIDs.indexOf(item.uuid) > -1) {
                    Analytics.addProduct(
                        item.itemId,//productId
                        item.itemName,//name
                        item.itemType,//category
                        item.owningProperty.propertyCode,//brand
                        (item.itemType === 'cash-wildcard' ? item.selectedCurrency + ' ' + item.itemTotalPrice : ''),//variant
                        item.itemTotalPrice,//price
                        '1',//quantity
                        '',//coupon
                        index//position
                    );
                }
            });

            //analytics... UA-XXXXXX
            Analytics.trackTransaction(
                confirmationUuid,//confirmationUUID
                'In1Solutions',//affiliation
                transaction.transactionAmount,//revenue
                '',//tax
                transaction.transactionShippingAmount,//shipping
                '',//coupon
                '',//list
                '',//step
                ''//option
            );

            //GTM datalayer push
            TagManager.trackPurchase(transaction, vm.shoppingCart, confirmationUuid);

            if (GoogleAdwordsService.isInitialized()) {
                GoogleAdwordsService.googleTrackConversion(transaction.transactionAmount, transaction.transactionCurrency, confirmationUuid);
            }
        }

	}
})();
